import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticImage } from "gatsby-plugin-image"

const Services = () => {
    return (
        <div className="container py-3 py-lg-4">
            <div className="row g-0 mb-3">
                <div className="col border-bottom border-1 border-dark">
                    <h1>Our Services</h1>
                </div>
            </div>

            <div className="row gy-md-4 gy-2 mb-3 mb-md-5">
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                    <div className="card card-news h-100 mb-3 bg-light border-0">
                        <StaticImage
                            aspectRatio={3 / 2}
                            width={540}
                            className="rounded-top"
                            alt="Physical"
                            src="../../../images/other/bulker4.jpg"
                            placeholder="tracedSVG"
                        />

                        <div className="card-body d-flex flex-column">
                            <h4 className="card-title ">Drybulk & Tanker Ocean Freight</h4>

                            <p className="card-text">
                                A blended chartering strategy that utilises a multi-prong approach of Time Charters, arbitrage of cargo
                                and ships.
                            </p>
                            <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                <Link to="/bulk-logistics/services/drybulk-and-tanker-ocean-freight" className="text-dark">
                                    Learn more
                                    <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="ms-2 svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                    <div className="card card-news h-100 mb-3 bg-light border-0">
                        <StaticImage
                            aspectRatio={3 / 2}
                            width={540}
                            className="rounded-top"
                            alt="Physical"
                            src="../../../images/other/bulker1.jpg"
                            placeholder="tracedSVG"
                        />

                        <div className="card-body d-flex flex-column">
                            <h4 className="card-title ">Commercial Management</h4>

                            <p className="card-text">
                                Providing full management to ship owners as the team has a track record of exceeding the Index.
                            </p>
                            <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                <Link to="/bulk-logistics/services/commercial-management" className="text-dark">
                                    Learn more 
                                    <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="ms-2 svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                    <div className="card card-news h-100 mb-3 bg-light border-0">
                        <StaticImage
                            aspectRatio={3 / 2}
                            width={540}
                            className="rounded-top"
                            alt="Physical"
                            src="../../../images/other/building1.jpg"
                            placeholder="tracedSVG"
                        />

                        <div className="card-body d-flex flex-column">
                            <h4 className="card-title ">Risk Management</h4>

                            <p className="card-text">
                                Providing Owners and Charterer with Risk management tools to cover their freight and oil exposures with
                                innovative structures.
                            </p>
                            <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                <Link to="/bulk-logistics/services/risk-management" className="text-dark">
                                    Learn more 
                                    <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="ms-2 svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                    <div className="card card-news h-100 mb-3 bg-light border-0">
                        <StaticImage
                            aspectRatio={3 / 2}
                            width={540}
                            className="rounded-top"
                            alt="Physical"
                            src="../../../images/other/services-road-1.png"
                            placeholder="tracedSVG"
                        />

                        <div className="card-body d-flex flex-column">
                            <h4 className="card-title ">Road Transport</h4>

                            <p className="card-text">
                                We provide full load transport and container services with our own, well maintained, fleet of 50 trucks
                                and vehicles.
                            </p>
                            <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                <a className="link-dark " href="https://www.vishwaacarriers.com/" target="_blank" rel="noreferrer">
                                    Learn more 
                                    <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="ms-2 svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mb-3 mb-lg-0">
                    <div className="card card-news h-100 mb-3 bg-light border-0">
                        <StaticImage
                            aspectRatio={3 / 2}
                            width={540}
                            className="rounded-top"
                            alt="Physical"
                            src="../../../images/other/storage.jpg"
                            placeholder="tracedSVG"
                        />

                        <div className="card-body d-flex flex-column">
                            <h4 className="card-title ">Storage</h4>

                            <p className="card-text">Coming soon..</p>
                            <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                <Link to="/bulk-logistics/services/storage" className="text-dark">
                                    Learn more 
                                    <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="ms-2 svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mb-3 mb-lg-0">
                    <div className="card card-news h-100 mb-3 bg-light border-0">
                        <StaticImage
                            aspectRatio={3 / 2}
                            width={540}
                            className="rounded-top"
                            alt="Physical"
                            src="../../../images/other/twu4.jpg"
                            placeholder="tracedSVG"
                        />

                        <div className="card-body d-flex flex-column">
                            <h4 className="card-title ">Three Wheels United</h4>

                            <p className="card-text">
                                Tech-enchanced finance company that enables electrical mobility in emerging markets.
                            </p>
                            <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                <a
                                    className="ms-lg-3 text-dark"
                                    type="button"
                                    href="https://www.threewheelsunited.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Learn more 
                                    <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="ms-2 svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
