import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const LatestNews = () => {
    const data = useStaticQuery(graphql`
        query ShippingLastestNews {
            allMdx(
                sort: { fields: [frontmatter___date], order: DESC }
                filter: { frontmatter: { devision: { eq: "bulk logistics" } } }
                limit: 3
            ) {
                nodes {
                    slug
                    excerpt
                    frontmatter {
                        date(formatString: "Do MMMM YYYY ")
                        title
                        description
                        author
                        embeddedCoverImageLocal {
                            childImageSharp {
                                gatsbyImageData(width: 694, aspectRatio: 3, transformOptions: { cropFocus: CENTER })
                            }
                        }
                    }
                }
            }
        }
    `)
    const posts = data.allMdx.nodes

    return (
        <div className="container py-2 py-md-3 py-lg-4">
            <div className="row mb-3 g-0">
                <div className="col d-flex border-bottom border-1 border-dark ">
                    <h1 className="">Latest news</h1>
                    <Link className="align-self-center ms-auto" to="/news">
                        Read more 
                        <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="ms-2 svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                    </Link>
                </div>
            </div>
            <div className="row mb-3 ">
                <div className="col ">
                    {posts.length === 0 ? (
                        <p>There are no press releases available.</p>
                    ) : (
                        posts.map((post, idx) => {
                            const title = post.frontmatter.title || post.fields.slug

                            return (
                                <div className="row" key={idx}>
                                    <div className="col-12">
                                        <div className="card mb-3 border-0">
                                            <div className="row g-0">
                                                <div className="col-12 col-lg-4 order-1 order-md-2 d-flex justify-content-center  align-items-center">
                                                    <GatsbyImage
                                                        image={getImage(post.frontmatter.embeddedCoverImageLocal?.[0])}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-8 order-2 ">
                                                    <div className="card-body h-100 gray-bg d-flex flex-column">
                                                        <Link to={"/news/" + post.slug}>
                                                            <h5 className="card-title">{title}</h5>
                                                        </Link>
                                                        <div className="card-text">
                                                            <p>{post.excerpt}</p>
                                                        </div>
                                                        <div className="card-text d-flex mt-auto">
                                                            <span className="">{post.frontmatter.date}</span>
                                                            <span className="border-start border-delta-red-dark border-2 ms-2 ps-2">
                                                                by {post.frontmatter.author}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
        </div>
    )
}

export default LatestNews
